import revive_payload_client_aFwy6yT6hg from "/root/workspace/dcy-web_F0Xe/node_modules/.pnpm/nuxt@3.8.0_@types+node@20.17.30_eslint@8.57.1_less@4.2.2_rollup@2.79.2_typescript@5.8.2_vite@4.5.11_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_6k6kdasVtH from "/root/workspace/dcy-web_F0Xe/node_modules/.pnpm/nuxt@3.8.0_@types+node@20.17.30_eslint@8.57.1_less@4.2.2_rollup@2.79.2_typescript@5.8.2_vite@4.5.11_vue-tsc@1.8.27/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_rvPBbrAwWV from "/root/workspace/dcy-web_F0Xe/node_modules/.pnpm/nuxt@3.8.0_@types+node@20.17.30_eslint@8.57.1_less@4.2.2_rollup@2.79.2_typescript@5.8.2_vite@4.5.11_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_8KIiLBLrUA from "/root/workspace/dcy-web_F0Xe/node_modules/.pnpm/@pinia+nuxt@0.5.5_typescript@5.8.2_vue@3.5.13/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/root/workspace/dcy-web_F0Xe/apps/admin/.nuxt/components.plugin.mjs";
import prefetch_client_VJpiH6q7ZD from "/root/workspace/dcy-web_F0Xe/node_modules/.pnpm/nuxt@3.8.0_@types+node@20.17.30_eslint@8.57.1_less@4.2.2_rollup@2.79.2_typescript@5.8.2_vite@4.5.11_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_OsfWq8PSjG from "/root/workspace/dcy-web_F0Xe/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.6_rollup@3.29.5_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_8z3rEpNIb5 from "/root/workspace/dcy-web_F0Xe/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.6_rollup@3.29.5_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_client_9Hah80d11D from "/root/workspace/dcy-web_F0Xe/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import unocss_MzCDxu9LMj from "/root/workspace/dcy-web_F0Xe/apps/admin/.nuxt/unocss.mjs";
import chunk_reload_client_2JClo1VWWR from "/root/workspace/dcy-web_F0Xe/node_modules/.pnpm/nuxt@3.8.0_@types+node@20.17.30_eslint@8.57.1_less@4.2.2_rollup@2.79.2_typescript@5.8.2_vite@4.5.11_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_t628Oz1hyX from "/root/workspace/dcy-web_F0Xe/node_modules/.pnpm/nuxt@3.8.0_@types+node@20.17.30_eslint@8.57.1_less@4.2.2_rollup@2.79.2_typescript@5.8.2_vite@4.5.11_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_mn6kkP7jPW from "/root/workspace/dcy-web_F0Xe/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import arco_design_icon_mmwhkaCiTW from "/root/workspace/dcy-web_F0Xe/apps/admin/plugins/arco-design-icon.ts";
import iconfont_MoxupmSx7j from "/root/workspace/dcy-web_F0Xe/apps/admin/plugins/iconfont.ts";
import permission_RYX9gEepuf from "/root/workspace/dcy-web_F0Xe/apps/admin/plugins/permission.ts";
import quill_editor_client_tupKYLQr8r from "/root/workspace/dcy-web_F0Xe/apps/admin/plugins/quill-editor.client.ts";
import vue_office_pdf_client_1QeVzdI8HH from "/root/workspace/dcy-web_F0Xe/apps/admin/plugins/vue-office-pdf.client.ts";
export default [
  revive_payload_client_aFwy6yT6hg,
  unhead_6k6kdasVtH,
  router_rvPBbrAwWV,
  plugin_vue3_8KIiLBLrUA,
  components_plugin_KR1HBZs4kY,
  prefetch_client_VJpiH6q7ZD,
  composition_OsfWq8PSjG,
  i18n_8z3rEpNIb5,
  plugin_client_9Hah80d11D,
  unocss_MzCDxu9LMj,
  chunk_reload_client_2JClo1VWWR,
  check_outdated_build_client_t628Oz1hyX,
  plugin_mn6kkP7jPW,
  arco_design_icon_mmwhkaCiTW,
  iconfont_MoxupmSx7j,
  permission_RYX9gEepuf,
  quill_editor_client_tupKYLQr8r,
  vue_office_pdf_client_1QeVzdI8HH
]