import { default as _91_46_46_46all_93iv2gNiWHUpMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/[...all].vue?macro=true";
import { default as _401QbTXRC2ZpNMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/401.vue?macro=true";
import { default as indexMrUKVw9o65Meta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/appmanage/application/index.vue?macro=true";
import { default as indexHM6VOipiFIMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/appmanage/major-directory/index.vue?macro=true";
import { default as indexcbXJdafmHoMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/appmanage/menus/index.vue?macro=true";
import { default as indexzkZ6LBqkzgMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/appmanage/platform/index.vue?macro=true";
import { default as indexA93WUIucjjMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/appmanage/tag/index.vue?macro=true";
import { default as appmanagep8ysKHlYYKMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/appmanage.vue?macro=true";
import { default as indexX4xlbmr95IMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/basic-data-management/grade-and-class/index.vue?macro=true";
import { default as index2toQaUZxxfMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/basic-data-management/major-management/index.vue?macro=true";
import { default as indexgKOh7FSaU5Meta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/basic-data-management/professional-management/index.vue?macro=true";
import { default as addrkA41RCcalMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/basic/notice-manage/add.vue?macro=true";
import { default as editXqituYUhq6Meta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/basic/notice-manage/edit.vue?macro=true";
import { default as indexuUG1rYVsOhMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/basic/notice-manage/index.vue?macro=true";
import { default as index9VS2JWSH70Meta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/booking-center/booking-verify/index.vue?macro=true";
import { default as indexDJpQkRmhXzMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/course-preview/index.vue?macro=true";
import { default as indexNeqtlpCfT0Meta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/dict/data/index.vue?macro=true";
import { default as indexXu93EwSwxWMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/dict/index.vue?macro=true";
import { default as index3QQPhKrZtRMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/equipment-center/device-management/detail/index.vue?macro=true";
import { default as indexZW0o3E67dLMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/equipment-center/device-management/index.vue?macro=true";
import { default as indexjm79xSo1kQMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/equipment-center/lab-management/detail/index.vue?macro=true";
import { default as indexJNq2yZpQSLMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/equipment-center/lab-management/index.vue?macro=true";
import { default as indexo5sINSxJJsMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/equipment-center/product-management/detail/index.vue?macro=true";
import { default as indexqlDNzoyiFvMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/equipment-center/product-management/index.vue?macro=true";
import { default as indexfopc20wyZEMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/equipment-center/scene-management/detail/index.vue?macro=true";
import { default as indexmwtiSf6FfLMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/equipment-center/scene-management/index.vue?macro=true";
import { default as indexdoEudkPgJ0Meta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/index.vue?macro=true";
import { default as indexag7xR6qgQTMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/lesson-library-detail-preview/index.vue?macro=true";
import { default as indexBgaxn2Ax9sMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/org-resource-management/course-data/index.vue?macro=true";
import { default as indexRRmQZWYNukMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/org-resource-management/exercise-data/index.vue?macro=true";
import { default as indexC35VnOCgVlMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/organization-management/index.vue?macro=true";
import { default as indexgnmOFORWtxMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/organization-management/organization/index.vue?macro=true";
import { default as indexeyHudLQO9yMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/personal/index.vue?macro=true";
import { default as indexN2LyvMUqCnMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/platform-resource-management/quality-course/index.vue?macro=true";
import { default as indexCFCtl9j8SkMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/platform-resource-management/quality-exercise/index.vue?macro=true";
import { default as indexu09jc4QxE0Meta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/platform-user-management/index.vue?macro=true";
import { default as ReceiveResourcesModallJmfDqqdY7Meta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/project/project-data/compontents/ReceiveResourcesModal.vue?macro=true";
import { default as indexBoL1AQVoOlMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/project/project-data/index.vue?macro=true";
import { default as indexv1fpMbZor2Meta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/project/project-management-list/index.vue?macro=true";
import { default as EditDrawerModal4cA5Z19vX5Meta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/project/project-recommended/compontents/EditDrawerModal.vue?macro=true";
import { default as PublishModalYs6BIGIFBmMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/project/project-recommended/compontents/PublishModal.vue?macro=true";
import { default as indexR7XxLfSxVwMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/project/project-recommended/index.vue?macro=true";
import { default as index2uK5kuz6SrMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/project/project-resources/detail/index.vue?macro=true";
import { default as indexbcDpReBVBRMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/school-based-resource-management/course-management/index.vue?macro=true";
import { default as indexWfXU8HYtO3Meta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/school-based-resource-management/exercise-management/index.vue?macro=true";
import { default as indexbtBGzBOlocMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/task-library-detail-preview/index.vue?macro=true";
import { default as indexYpnZFFdVrEMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/course-management/detail/course-basic/index.vue?macro=true";
import { default as index2cvnUdurVGMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/course-management/detail/course-class/index.vue?macro=true";
import { default as indexoswKx0DbY4Meta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/course-management/detail/course-detail/chapter/index.vue?macro=true";
import { default as _91pid_93eFt977Cd5iMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/course-management/detail/course-detail/chapter/preview/[cid]/[pid].vue?macro=true";
import { default as detailrKN4vgoDdVMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/course-management/detail/course-detail/exam/detail.vue?macro=true";
import { default as indexs76zN8LobQMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/course-management/detail/course-detail/exam/index.vue?macro=true";
import { default as indexbQL6C3mHL4Meta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/course-management/detail/course-detail/experiment/index.vue?macro=true";
import { default as indexEtoUoVBL3YMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/course-management/detail/course-detail/index.vue?macro=true";
import { default as indexz3BhcPYAa2Meta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/course-management/detail/course-detail/knowledge-graph/index.vue?macro=true";
import { default as index1qzRGFtCkpMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/course-management/detail/course-detail/material/index.vue?macro=true";
import { default as index4SB1oUI5cUMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/course-management/detail/course-detail/outline/index.vue?macro=true";
import { default as indexFbemjvC9VeMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/course-management/detail/course-detail/task/index.vue?macro=true";
import { default as BasicInfoQneHldaV9SMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/course-management/detail/course-detail/task/task-detail/container/BasicInfo.vue?macro=true";
import { default as ExerciseListTabWdu9u3d2wyMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/course-management/detail/course-detail/task/task-detail/container/ExerciseListTab.vue?macro=true";
import { default as StudentJudgeListTabX7YKvHlkglMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/course-management/detail/course-detail/task/task-detail/container/StudentJudgeListTab.vue?macro=true";
import { default as StudentScoreListTabOEwQBGwNtuMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/course-management/detail/course-detail/task/task-detail/container/StudentScoreListTab.vue?macro=true";
import { default as indexjk6KSO6Z4uMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/course-management/detail/course-detail/task/task-detail/index.vue?macro=true";
import { default as indexgrI7aVXeg9Meta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/course-management/detail/course-detail/teaching-plan/index.vue?macro=true";
import { default as indexCnmMjh9q28Meta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/course-management/detail/course-goal-achievement/index.vue?macro=true";
import { default as indexJIlFyIFfdgMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/course-management/detail/index.vue?macro=true";
import { default as indexACZq5lKz2LMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/course-management/index.vue?macro=true";
import { default as indexulMXEdpN98Meta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/professional-curriculum-system/details/index.vue?macro=true";
import { default as index0p18kaILJ2Meta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/professional-curriculum-system/index.vue?macro=true";
import { default as indexdIB29Kf55sMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/user-management/operate-user-management/index.vue?macro=true";
import { default as indexEudCnD6VdMMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/user-management/role-management/index.vue?macro=true";
import { default as indexpHrCUqK3tNMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/user-management/student-management/index.vue?macro=true";
import { default as indexH1pCGtOySaMeta } from "/root/workspace/dcy-web_F0Xe/apps/admin/pages/user-management/teacher-management/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46all_93iv2gNiWHUpMeta?.name ?? "all",
    path: _91_46_46_46all_93iv2gNiWHUpMeta?.path ?? "/:all(.*)*",
    meta: _91_46_46_46all_93iv2gNiWHUpMeta || {},
    alias: _91_46_46_46all_93iv2gNiWHUpMeta?.alias || [],
    redirect: _91_46_46_46all_93iv2gNiWHUpMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: _401QbTXRC2ZpNMeta?.name ?? "401",
    path: _401QbTXRC2ZpNMeta?.path ?? "/401",
    meta: _401QbTXRC2ZpNMeta || {},
    alias: _401QbTXRC2ZpNMeta?.alias || [],
    redirect: _401QbTXRC2ZpNMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/401.vue").then(m => m.default || m)
  },
  {
    name: appmanagep8ysKHlYYKMeta?.name ?? "appmanage",
    path: appmanagep8ysKHlYYKMeta?.path ?? "/appmanage",
    children: [
  {
    name: indexMrUKVw9o65Meta?.name ?? "appmanage-application",
    path: indexMrUKVw9o65Meta?.path ?? "application",
    meta: indexMrUKVw9o65Meta || {},
    alias: indexMrUKVw9o65Meta?.alias || [],
    redirect: indexMrUKVw9o65Meta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/appmanage/application/index.vue").then(m => m.default || m)
  },
  {
    name: indexHM6VOipiFIMeta?.name ?? "appmanage-major-directory",
    path: indexHM6VOipiFIMeta?.path ?? "major-directory",
    meta: indexHM6VOipiFIMeta || {},
    alias: indexHM6VOipiFIMeta?.alias || [],
    redirect: indexHM6VOipiFIMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/appmanage/major-directory/index.vue").then(m => m.default || m)
  },
  {
    name: indexcbXJdafmHoMeta?.name ?? "appmanage-menus",
    path: indexcbXJdafmHoMeta?.path ?? "menus",
    meta: indexcbXJdafmHoMeta || {},
    alias: indexcbXJdafmHoMeta?.alias || [],
    redirect: indexcbXJdafmHoMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/appmanage/menus/index.vue").then(m => m.default || m)
  },
  {
    name: indexzkZ6LBqkzgMeta?.name ?? "appmanage-platform",
    path: indexzkZ6LBqkzgMeta?.path ?? "platform",
    meta: indexzkZ6LBqkzgMeta || {},
    alias: indexzkZ6LBqkzgMeta?.alias || [],
    redirect: indexzkZ6LBqkzgMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/appmanage/platform/index.vue").then(m => m.default || m)
  },
  {
    name: indexA93WUIucjjMeta?.name ?? "appmanage-tag",
    path: indexA93WUIucjjMeta?.path ?? "tag",
    meta: indexA93WUIucjjMeta || {},
    alias: indexA93WUIucjjMeta?.alias || [],
    redirect: indexA93WUIucjjMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/appmanage/tag/index.vue").then(m => m.default || m)
  }
],
    meta: appmanagep8ysKHlYYKMeta || {},
    alias: appmanagep8ysKHlYYKMeta?.alias || [],
    redirect: appmanagep8ysKHlYYKMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/appmanage.vue").then(m => m.default || m)
  },
  {
    name: indexX4xlbmr95IMeta?.name ?? "basic-data-management-grade-and-class",
    path: indexX4xlbmr95IMeta?.path ?? "/basic-data-management/grade-and-class",
    meta: indexX4xlbmr95IMeta || {},
    alias: indexX4xlbmr95IMeta?.alias || [],
    redirect: indexX4xlbmr95IMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/basic-data-management/grade-and-class/index.vue").then(m => m.default || m)
  },
  {
    name: index2toQaUZxxfMeta?.name ?? "basic-data-management-major-management",
    path: index2toQaUZxxfMeta?.path ?? "/basic-data-management/major-management",
    meta: index2toQaUZxxfMeta || {},
    alias: index2toQaUZxxfMeta?.alias || [],
    redirect: index2toQaUZxxfMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/basic-data-management/major-management/index.vue").then(m => m.default || m)
  },
  {
    name: indexgKOh7FSaU5Meta?.name ?? "basic-data-management-professional-management",
    path: indexgKOh7FSaU5Meta?.path ?? "/basic-data-management/professional-management",
    meta: indexgKOh7FSaU5Meta || {},
    alias: indexgKOh7FSaU5Meta?.alias || [],
    redirect: indexgKOh7FSaU5Meta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/basic-data-management/professional-management/index.vue").then(m => m.default || m)
  },
  {
    name: addrkA41RCcalMeta?.name ?? "通知公告新增页",
    path: addrkA41RCcalMeta?.path ?? "/basic/notice-manage/add",
    meta: addrkA41RCcalMeta || {},
    alias: addrkA41RCcalMeta?.alias || [],
    redirect: addrkA41RCcalMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/basic/notice-manage/add.vue").then(m => m.default || m)
  },
  {
    name: editXqituYUhq6Meta?.name ?? "通知公告编辑页",
    path: editXqituYUhq6Meta?.path ?? "/basic/notice-manage/edit",
    meta: editXqituYUhq6Meta || {},
    alias: editXqituYUhq6Meta?.alias || [],
    redirect: editXqituYUhq6Meta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/basic/notice-manage/edit.vue").then(m => m.default || m)
  },
  {
    name: indexuUG1rYVsOhMeta?.name ?? "basic-notice-manage",
    path: indexuUG1rYVsOhMeta?.path ?? "/basic/notice-manage",
    meta: indexuUG1rYVsOhMeta || {},
    alias: indexuUG1rYVsOhMeta?.alias || [],
    redirect: indexuUG1rYVsOhMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/basic/notice-manage/index.vue").then(m => m.default || m)
  },
  {
    name: index9VS2JWSH70Meta?.name ?? "booking-center-booking-verify",
    path: index9VS2JWSH70Meta?.path ?? "/booking-center/booking-verify",
    meta: index9VS2JWSH70Meta || {},
    alias: index9VS2JWSH70Meta?.alias || [],
    redirect: index9VS2JWSH70Meta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/booking-center/booking-verify/index.vue").then(m => m.default || m)
  },
  {
    name: indexDJpQkRmhXzMeta?.name ?? "course-preview",
    path: indexDJpQkRmhXzMeta?.path ?? "/course-preview",
    meta: indexDJpQkRmhXzMeta || {},
    alias: indexDJpQkRmhXzMeta?.alias || [],
    redirect: indexDJpQkRmhXzMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/course-preview/index.vue").then(m => m.default || m)
  },
  {
    name: indexNeqtlpCfT0Meta?.name ?? "dict-data",
    path: indexNeqtlpCfT0Meta?.path ?? "/dict/data",
    meta: indexNeqtlpCfT0Meta || {},
    alias: indexNeqtlpCfT0Meta?.alias || [],
    redirect: indexNeqtlpCfT0Meta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/dict/data/index.vue").then(m => m.default || m)
  },
  {
    name: indexXu93EwSwxWMeta?.name ?? "dict",
    path: indexXu93EwSwxWMeta?.path ?? "/dict",
    meta: indexXu93EwSwxWMeta || {},
    alias: indexXu93EwSwxWMeta?.alias || [],
    redirect: indexXu93EwSwxWMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/dict/index.vue").then(m => m.default || m)
  },
  {
    name: index3QQPhKrZtRMeta?.name ?? "equipment-center-device-management-detail",
    path: index3QQPhKrZtRMeta?.path ?? "/equipment-center/device-management/detail",
    meta: index3QQPhKrZtRMeta || {},
    alias: index3QQPhKrZtRMeta?.alias || [],
    redirect: index3QQPhKrZtRMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/equipment-center/device-management/detail/index.vue").then(m => m.default || m)
  },
  {
    name: indexZW0o3E67dLMeta?.name ?? "equipment-center-device-management",
    path: indexZW0o3E67dLMeta?.path ?? "/equipment-center/device-management",
    meta: indexZW0o3E67dLMeta || {},
    alias: indexZW0o3E67dLMeta?.alias || [],
    redirect: indexZW0o3E67dLMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/equipment-center/device-management/index.vue").then(m => m.default || m)
  },
  {
    name: indexjm79xSo1kQMeta?.name ?? "equipment-center-lab-management-detail",
    path: indexjm79xSo1kQMeta?.path ?? "/equipment-center/lab-management/detail",
    meta: indexjm79xSo1kQMeta || {},
    alias: indexjm79xSo1kQMeta?.alias || [],
    redirect: indexjm79xSo1kQMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/equipment-center/lab-management/detail/index.vue").then(m => m.default || m)
  },
  {
    name: indexJNq2yZpQSLMeta?.name ?? "equipment-center-lab-management",
    path: indexJNq2yZpQSLMeta?.path ?? "/equipment-center/lab-management",
    meta: indexJNq2yZpQSLMeta || {},
    alias: indexJNq2yZpQSLMeta?.alias || [],
    redirect: indexJNq2yZpQSLMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/equipment-center/lab-management/index.vue").then(m => m.default || m)
  },
  {
    name: indexo5sINSxJJsMeta?.name ?? "equipment-center-product-management-detail",
    path: indexo5sINSxJJsMeta?.path ?? "/equipment-center/product-management/detail",
    meta: indexo5sINSxJJsMeta || {},
    alias: indexo5sINSxJJsMeta?.alias || [],
    redirect: indexo5sINSxJJsMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/equipment-center/product-management/detail/index.vue").then(m => m.default || m)
  },
  {
    name: indexqlDNzoyiFvMeta?.name ?? "equipment-center-product-management",
    path: indexqlDNzoyiFvMeta?.path ?? "/equipment-center/product-management",
    meta: indexqlDNzoyiFvMeta || {},
    alias: indexqlDNzoyiFvMeta?.alias || [],
    redirect: indexqlDNzoyiFvMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/equipment-center/product-management/index.vue").then(m => m.default || m)
  },
  {
    name: indexfopc20wyZEMeta?.name ?? "equipment-center-scene-management-detail",
    path: indexfopc20wyZEMeta?.path ?? "/equipment-center/scene-management/detail",
    meta: indexfopc20wyZEMeta || {},
    alias: indexfopc20wyZEMeta?.alias || [],
    redirect: indexfopc20wyZEMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/equipment-center/scene-management/detail/index.vue").then(m => m.default || m)
  },
  {
    name: indexmwtiSf6FfLMeta?.name ?? "equipment-center-scene-management",
    path: indexmwtiSf6FfLMeta?.path ?? "/equipment-center/scene-management",
    meta: indexmwtiSf6FfLMeta || {},
    alias: indexmwtiSf6FfLMeta?.alias || [],
    redirect: indexmwtiSf6FfLMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/equipment-center/scene-management/index.vue").then(m => m.default || m)
  },
  {
    name: indexdoEudkPgJ0Meta?.name ?? "index",
    path: indexdoEudkPgJ0Meta?.path ?? "/",
    meta: indexdoEudkPgJ0Meta || {},
    alias: indexdoEudkPgJ0Meta?.alias || [],
    redirect: indexdoEudkPgJ0Meta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexag7xR6qgQTMeta?.name ?? "lesson-library-detail-preview",
    path: indexag7xR6qgQTMeta?.path ?? "/lesson-library-detail-preview",
    meta: indexag7xR6qgQTMeta || {},
    alias: indexag7xR6qgQTMeta?.alias || [],
    redirect: indexag7xR6qgQTMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/lesson-library-detail-preview/index.vue").then(m => m.default || m)
  },
  {
    name: indexBgaxn2Ax9sMeta?.name ?? "org-resource-management-course-data",
    path: indexBgaxn2Ax9sMeta?.path ?? "/org-resource-management/course-data",
    meta: indexBgaxn2Ax9sMeta || {},
    alias: indexBgaxn2Ax9sMeta?.alias || [],
    redirect: indexBgaxn2Ax9sMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/org-resource-management/course-data/index.vue").then(m => m.default || m)
  },
  {
    name: indexRRmQZWYNukMeta?.name ?? "org-resource-management-exercise-data",
    path: indexRRmQZWYNukMeta?.path ?? "/org-resource-management/exercise-data",
    meta: indexRRmQZWYNukMeta || {},
    alias: indexRRmQZWYNukMeta?.alias || [],
    redirect: indexRRmQZWYNukMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/org-resource-management/exercise-data/index.vue").then(m => m.default || m)
  },
  {
    name: indexC35VnOCgVlMeta?.name ?? "organization-management",
    path: indexC35VnOCgVlMeta?.path ?? "/organization-management",
    meta: indexC35VnOCgVlMeta || {},
    alias: indexC35VnOCgVlMeta?.alias || [],
    redirect: indexC35VnOCgVlMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/organization-management/index.vue").then(m => m.default || m)
  },
  {
    name: indexgnmOFORWtxMeta?.name ?? "organization-management-organization",
    path: indexgnmOFORWtxMeta?.path ?? "/organization-management/organization",
    meta: indexgnmOFORWtxMeta || {},
    alias: indexgnmOFORWtxMeta?.alias || [],
    redirect: indexgnmOFORWtxMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/organization-management/organization/index.vue").then(m => m.default || m)
  },
  {
    name: indexeyHudLQO9yMeta?.name ?? "personal",
    path: indexeyHudLQO9yMeta?.path ?? "/personal",
    meta: indexeyHudLQO9yMeta || {},
    alias: indexeyHudLQO9yMeta?.alias || [],
    redirect: indexeyHudLQO9yMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/personal/index.vue").then(m => m.default || m)
  },
  {
    name: indexN2LyvMUqCnMeta?.name ?? "platform-resource-management-quality-course",
    path: indexN2LyvMUqCnMeta?.path ?? "/platform-resource-management/quality-course",
    meta: indexN2LyvMUqCnMeta || {},
    alias: indexN2LyvMUqCnMeta?.alias || [],
    redirect: indexN2LyvMUqCnMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/platform-resource-management/quality-course/index.vue").then(m => m.default || m)
  },
  {
    name: indexCFCtl9j8SkMeta?.name ?? "platform-resource-management-quality-exercise",
    path: indexCFCtl9j8SkMeta?.path ?? "/platform-resource-management/quality-exercise",
    meta: indexCFCtl9j8SkMeta || {},
    alias: indexCFCtl9j8SkMeta?.alias || [],
    redirect: indexCFCtl9j8SkMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/platform-resource-management/quality-exercise/index.vue").then(m => m.default || m)
  },
  {
    name: indexu09jc4QxE0Meta?.name ?? "platform-user-management",
    path: indexu09jc4QxE0Meta?.path ?? "/platform-user-management",
    meta: indexu09jc4QxE0Meta || {},
    alias: indexu09jc4QxE0Meta?.alias || [],
    redirect: indexu09jc4QxE0Meta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/platform-user-management/index.vue").then(m => m.default || m)
  },
  {
    name: ReceiveResourcesModallJmfDqqdY7Meta?.name ?? "project-project-data-compontents-ReceiveResourcesModal",
    path: ReceiveResourcesModallJmfDqqdY7Meta?.path ?? "/project/project-data/compontents/ReceiveResourcesModal",
    meta: ReceiveResourcesModallJmfDqqdY7Meta || {},
    alias: ReceiveResourcesModallJmfDqqdY7Meta?.alias || [],
    redirect: ReceiveResourcesModallJmfDqqdY7Meta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/project/project-data/compontents/ReceiveResourcesModal.vue").then(m => m.default || m)
  },
  {
    name: indexBoL1AQVoOlMeta?.name ?? "project-project-data",
    path: indexBoL1AQVoOlMeta?.path ?? "/project/project-data",
    meta: indexBoL1AQVoOlMeta || {},
    alias: indexBoL1AQVoOlMeta?.alias || [],
    redirect: indexBoL1AQVoOlMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/project/project-data/index.vue").then(m => m.default || m)
  },
  {
    name: indexv1fpMbZor2Meta?.name ?? "project-project-management-list",
    path: indexv1fpMbZor2Meta?.path ?? "/project/project-management-list",
    meta: indexv1fpMbZor2Meta || {},
    alias: indexv1fpMbZor2Meta?.alias || [],
    redirect: indexv1fpMbZor2Meta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/project/project-management-list/index.vue").then(m => m.default || m)
  },
  {
    name: EditDrawerModal4cA5Z19vX5Meta?.name ?? "project-project-recommended-compontents-EditDrawerModal",
    path: EditDrawerModal4cA5Z19vX5Meta?.path ?? "/project/project-recommended/compontents/EditDrawerModal",
    meta: EditDrawerModal4cA5Z19vX5Meta || {},
    alias: EditDrawerModal4cA5Z19vX5Meta?.alias || [],
    redirect: EditDrawerModal4cA5Z19vX5Meta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/project/project-recommended/compontents/EditDrawerModal.vue").then(m => m.default || m)
  },
  {
    name: PublishModalYs6BIGIFBmMeta?.name ?? "project-project-recommended-compontents-PublishModal",
    path: PublishModalYs6BIGIFBmMeta?.path ?? "/project/project-recommended/compontents/PublishModal",
    meta: PublishModalYs6BIGIFBmMeta || {},
    alias: PublishModalYs6BIGIFBmMeta?.alias || [],
    redirect: PublishModalYs6BIGIFBmMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/project/project-recommended/compontents/PublishModal.vue").then(m => m.default || m)
  },
  {
    name: indexR7XxLfSxVwMeta?.name ?? "project-project-recommended",
    path: indexR7XxLfSxVwMeta?.path ?? "/project/project-recommended",
    meta: indexR7XxLfSxVwMeta || {},
    alias: indexR7XxLfSxVwMeta?.alias || [],
    redirect: indexR7XxLfSxVwMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/project/project-recommended/index.vue").then(m => m.default || m)
  },
  {
    name: index2uK5kuz6SrMeta?.name ?? "project-project-resources-detail",
    path: index2uK5kuz6SrMeta?.path ?? "/project/project-resources/detail",
    meta: index2uK5kuz6SrMeta || {},
    alias: index2uK5kuz6SrMeta?.alias || [],
    redirect: index2uK5kuz6SrMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/project/project-resources/detail/index.vue").then(m => m.default || m)
  },
  {
    name: indexbcDpReBVBRMeta?.name ?? "school-based-resource-management-course-management",
    path: indexbcDpReBVBRMeta?.path ?? "/school-based-resource-management/course-management",
    meta: indexbcDpReBVBRMeta || {},
    alias: indexbcDpReBVBRMeta?.alias || [],
    redirect: indexbcDpReBVBRMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/school-based-resource-management/course-management/index.vue").then(m => m.default || m)
  },
  {
    name: indexWfXU8HYtO3Meta?.name ?? "school-based-resource-management-exercise-management",
    path: indexWfXU8HYtO3Meta?.path ?? "/school-based-resource-management/exercise-management",
    meta: indexWfXU8HYtO3Meta || {},
    alias: indexWfXU8HYtO3Meta?.alias || [],
    redirect: indexWfXU8HYtO3Meta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/school-based-resource-management/exercise-management/index.vue").then(m => m.default || m)
  },
  {
    name: indexbtBGzBOlocMeta?.name ?? "task-library-detail-preview",
    path: indexbtBGzBOlocMeta?.path ?? "/task-library-detail-preview",
    meta: indexbtBGzBOlocMeta || {},
    alias: indexbtBGzBOlocMeta?.alias || [],
    redirect: indexbtBGzBOlocMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/task-library-detail-preview/index.vue").then(m => m.default || m)
  },
  {
    name: indexYpnZFFdVrEMeta?.name ?? "teaching-management-course-management-detail-course-basic",
    path: indexYpnZFFdVrEMeta?.path ?? "/teaching-management/course-management/detail/course-basic",
    meta: indexYpnZFFdVrEMeta || {},
    alias: indexYpnZFFdVrEMeta?.alias || [],
    redirect: indexYpnZFFdVrEMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/course-management/detail/course-basic/index.vue").then(m => m.default || m)
  },
  {
    name: index2cvnUdurVGMeta?.name ?? "teaching-management-course-management-detail-course-class",
    path: index2cvnUdurVGMeta?.path ?? "/teaching-management/course-management/detail/course-class",
    meta: index2cvnUdurVGMeta || {},
    alias: index2cvnUdurVGMeta?.alias || [],
    redirect: index2cvnUdurVGMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/course-management/detail/course-class/index.vue").then(m => m.default || m)
  },
  {
    name: indexoswKx0DbY4Meta?.name ?? "teaching-management-course-management-detail-course-detail-chapter",
    path: indexoswKx0DbY4Meta?.path ?? "/teaching-management/course-management/detail/course-detail/chapter",
    meta: indexoswKx0DbY4Meta || {},
    alias: indexoswKx0DbY4Meta?.alias || [],
    redirect: indexoswKx0DbY4Meta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/course-management/detail/course-detail/chapter/index.vue").then(m => m.default || m)
  },
  {
    name: _91pid_93eFt977Cd5iMeta?.name ?? "teaching-management-course-management-detail-course-detail-chapter-preview-cid-pid",
    path: _91pid_93eFt977Cd5iMeta?.path ?? "/teaching-management/course-management/detail/course-detail/chapter/preview/:cid()/:pid()",
    meta: _91pid_93eFt977Cd5iMeta || {},
    alias: _91pid_93eFt977Cd5iMeta?.alias || [],
    redirect: _91pid_93eFt977Cd5iMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/course-management/detail/course-detail/chapter/preview/[cid]/[pid].vue").then(m => m.default || m)
  },
  {
    name: detailrKN4vgoDdVMeta?.name ?? "teaching-management-course-management-detail-course-detail-exam-detail",
    path: detailrKN4vgoDdVMeta?.path ?? "/teaching-management/course-management/detail/course-detail/exam/detail",
    meta: detailrKN4vgoDdVMeta || {},
    alias: detailrKN4vgoDdVMeta?.alias || [],
    redirect: detailrKN4vgoDdVMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/course-management/detail/course-detail/exam/detail.vue").then(m => m.default || m)
  },
  {
    name: indexs76zN8LobQMeta?.name ?? "teaching-management-course-management-detail-course-detail-exam",
    path: indexs76zN8LobQMeta?.path ?? "/teaching-management/course-management/detail/course-detail/exam",
    meta: indexs76zN8LobQMeta || {},
    alias: indexs76zN8LobQMeta?.alias || [],
    redirect: indexs76zN8LobQMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/course-management/detail/course-detail/exam/index.vue").then(m => m.default || m)
  },
  {
    name: indexbQL6C3mHL4Meta?.name ?? "teaching-management-course-management-detail-course-detail-experiment",
    path: indexbQL6C3mHL4Meta?.path ?? "/teaching-management/course-management/detail/course-detail/experiment",
    meta: indexbQL6C3mHL4Meta || {},
    alias: indexbQL6C3mHL4Meta?.alias || [],
    redirect: indexbQL6C3mHL4Meta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/course-management/detail/course-detail/experiment/index.vue").then(m => m.default || m)
  },
  {
    name: indexEtoUoVBL3YMeta?.name ?? "teaching-management-course-management-detail-course-detail",
    path: indexEtoUoVBL3YMeta?.path ?? "/teaching-management/course-management/detail/course-detail",
    meta: indexEtoUoVBL3YMeta || {},
    alias: indexEtoUoVBL3YMeta?.alias || [],
    redirect: indexEtoUoVBL3YMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/course-management/detail/course-detail/index.vue").then(m => m.default || m)
  },
  {
    name: indexz3BhcPYAa2Meta?.name ?? "teaching-management-course-management-detail-course-detail-knowledge-graph",
    path: indexz3BhcPYAa2Meta?.path ?? "/teaching-management/course-management/detail/course-detail/knowledge-graph",
    meta: indexz3BhcPYAa2Meta || {},
    alias: indexz3BhcPYAa2Meta?.alias || [],
    redirect: indexz3BhcPYAa2Meta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/course-management/detail/course-detail/knowledge-graph/index.vue").then(m => m.default || m)
  },
  {
    name: index1qzRGFtCkpMeta?.name ?? "teaching-management-course-management-detail-course-detail-material",
    path: index1qzRGFtCkpMeta?.path ?? "/teaching-management/course-management/detail/course-detail/material",
    meta: index1qzRGFtCkpMeta || {},
    alias: index1qzRGFtCkpMeta?.alias || [],
    redirect: index1qzRGFtCkpMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/course-management/detail/course-detail/material/index.vue").then(m => m.default || m)
  },
  {
    name: index4SB1oUI5cUMeta?.name ?? "teaching-management-course-management-detail-course-detail-outline",
    path: index4SB1oUI5cUMeta?.path ?? "/teaching-management/course-management/detail/course-detail/outline",
    meta: index4SB1oUI5cUMeta || {},
    alias: index4SB1oUI5cUMeta?.alias || [],
    redirect: index4SB1oUI5cUMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/course-management/detail/course-detail/outline/index.vue").then(m => m.default || m)
  },
  {
    name: indexFbemjvC9VeMeta?.name ?? "teaching-management-course-management-detail-course-detail-task",
    path: indexFbemjvC9VeMeta?.path ?? "/teaching-management/course-management/detail/course-detail/task",
    meta: indexFbemjvC9VeMeta || {},
    alias: indexFbemjvC9VeMeta?.alias || [],
    redirect: indexFbemjvC9VeMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/course-management/detail/course-detail/task/index.vue").then(m => m.default || m)
  },
  {
    name: BasicInfoQneHldaV9SMeta?.name ?? "teaching-management-course-management-detail-course-detail-task-task-detail-container-BasicInfo",
    path: BasicInfoQneHldaV9SMeta?.path ?? "/teaching-management/course-management/detail/course-detail/task/task-detail/container/BasicInfo",
    meta: BasicInfoQneHldaV9SMeta || {},
    alias: BasicInfoQneHldaV9SMeta?.alias || [],
    redirect: BasicInfoQneHldaV9SMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/course-management/detail/course-detail/task/task-detail/container/BasicInfo.vue").then(m => m.default || m)
  },
  {
    name: ExerciseListTabWdu9u3d2wyMeta?.name ?? "teaching-management-course-management-detail-course-detail-task-task-detail-container-ExerciseListTab",
    path: ExerciseListTabWdu9u3d2wyMeta?.path ?? "/teaching-management/course-management/detail/course-detail/task/task-detail/container/ExerciseListTab",
    meta: ExerciseListTabWdu9u3d2wyMeta || {},
    alias: ExerciseListTabWdu9u3d2wyMeta?.alias || [],
    redirect: ExerciseListTabWdu9u3d2wyMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/course-management/detail/course-detail/task/task-detail/container/ExerciseListTab.vue").then(m => m.default || m)
  },
  {
    name: StudentJudgeListTabX7YKvHlkglMeta?.name ?? "teaching-management-course-management-detail-course-detail-task-task-detail-container-StudentJudgeListTab",
    path: StudentJudgeListTabX7YKvHlkglMeta?.path ?? "/teaching-management/course-management/detail/course-detail/task/task-detail/container/StudentJudgeListTab",
    meta: StudentJudgeListTabX7YKvHlkglMeta || {},
    alias: StudentJudgeListTabX7YKvHlkglMeta?.alias || [],
    redirect: StudentJudgeListTabX7YKvHlkglMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/course-management/detail/course-detail/task/task-detail/container/StudentJudgeListTab.vue").then(m => m.default || m)
  },
  {
    name: StudentScoreListTabOEwQBGwNtuMeta?.name ?? "teaching-management-course-management-detail-course-detail-task-task-detail-container-StudentScoreListTab",
    path: StudentScoreListTabOEwQBGwNtuMeta?.path ?? "/teaching-management/course-management/detail/course-detail/task/task-detail/container/StudentScoreListTab",
    meta: StudentScoreListTabOEwQBGwNtuMeta || {},
    alias: StudentScoreListTabOEwQBGwNtuMeta?.alias || [],
    redirect: StudentScoreListTabOEwQBGwNtuMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/course-management/detail/course-detail/task/task-detail/container/StudentScoreListTab.vue").then(m => m.default || m)
  },
  {
    name: indexjk6KSO6Z4uMeta?.name ?? "teaching-management-course-management-detail-course-detail-task-task-detail",
    path: indexjk6KSO6Z4uMeta?.path ?? "/teaching-management/course-management/detail/course-detail/task/task-detail",
    meta: indexjk6KSO6Z4uMeta || {},
    alias: indexjk6KSO6Z4uMeta?.alias || [],
    redirect: indexjk6KSO6Z4uMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/course-management/detail/course-detail/task/task-detail/index.vue").then(m => m.default || m)
  },
  {
    name: indexgrI7aVXeg9Meta?.name ?? "teaching-management-course-management-detail-course-detail-teaching-plan",
    path: indexgrI7aVXeg9Meta?.path ?? "/teaching-management/course-management/detail/course-detail/teaching-plan",
    meta: indexgrI7aVXeg9Meta || {},
    alias: indexgrI7aVXeg9Meta?.alias || [],
    redirect: indexgrI7aVXeg9Meta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/course-management/detail/course-detail/teaching-plan/index.vue").then(m => m.default || m)
  },
  {
    name: indexCnmMjh9q28Meta?.name ?? "teaching-management-course-management-detail-course-goal-achievement",
    path: indexCnmMjh9q28Meta?.path ?? "/teaching-management/course-management/detail/course-goal-achievement",
    meta: indexCnmMjh9q28Meta || {},
    alias: indexCnmMjh9q28Meta?.alias || [],
    redirect: indexCnmMjh9q28Meta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/course-management/detail/course-goal-achievement/index.vue").then(m => m.default || m)
  },
  {
    name: indexJIlFyIFfdgMeta?.name ?? "teaching-management-course-management-detail",
    path: indexJIlFyIFfdgMeta?.path ?? "/teaching-management/course-management/detail",
    meta: indexJIlFyIFfdgMeta || {},
    alias: indexJIlFyIFfdgMeta?.alias || [],
    redirect: indexJIlFyIFfdgMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/course-management/detail/index.vue").then(m => m.default || m)
  },
  {
    name: indexACZq5lKz2LMeta?.name ?? "teaching-management-course-management",
    path: indexACZq5lKz2LMeta?.path ?? "/teaching-management/course-management",
    meta: indexACZq5lKz2LMeta || {},
    alias: indexACZq5lKz2LMeta?.alias || [],
    redirect: indexACZq5lKz2LMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/course-management/index.vue").then(m => m.default || m)
  },
  {
    name: indexulMXEdpN98Meta?.name ?? "details",
    path: indexulMXEdpN98Meta?.path ?? "/teaching-management/professional-curriculum-system/details",
    meta: indexulMXEdpN98Meta || {},
    alias: indexulMXEdpN98Meta?.alias || [],
    redirect: indexulMXEdpN98Meta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/professional-curriculum-system/details/index.vue").then(m => m.default || m)
  },
  {
    name: index0p18kaILJ2Meta?.name ?? "teaching-management-professional-curriculum-system",
    path: index0p18kaILJ2Meta?.path ?? "/teaching-management/professional-curriculum-system",
    meta: index0p18kaILJ2Meta || {},
    alias: index0p18kaILJ2Meta?.alias || [],
    redirect: index0p18kaILJ2Meta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/teaching-management/professional-curriculum-system/index.vue").then(m => m.default || m)
  },
  {
    name: indexdIB29Kf55sMeta?.name ?? "user-management-operate-user-management",
    path: indexdIB29Kf55sMeta?.path ?? "/user-management/operate-user-management",
    meta: indexdIB29Kf55sMeta || {},
    alias: indexdIB29Kf55sMeta?.alias || [],
    redirect: indexdIB29Kf55sMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/user-management/operate-user-management/index.vue").then(m => m.default || m)
  },
  {
    name: indexEudCnD6VdMMeta?.name ?? "user-management-role-management",
    path: indexEudCnD6VdMMeta?.path ?? "/user-management/role-management",
    meta: indexEudCnD6VdMMeta || {},
    alias: indexEudCnD6VdMMeta?.alias || [],
    redirect: indexEudCnD6VdMMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/user-management/role-management/index.vue").then(m => m.default || m)
  },
  {
    name: indexpHrCUqK3tNMeta?.name ?? "user-management-student-management",
    path: indexpHrCUqK3tNMeta?.path ?? "/user-management/student-management",
    meta: indexpHrCUqK3tNMeta || {},
    alias: indexpHrCUqK3tNMeta?.alias || [],
    redirect: indexpHrCUqK3tNMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/user-management/student-management/index.vue").then(m => m.default || m)
  },
  {
    name: indexH1pCGtOySaMeta?.name ?? "user-management-teacher-management",
    path: indexH1pCGtOySaMeta?.path ?? "/user-management/teacher-management",
    meta: indexH1pCGtOySaMeta || {},
    alias: indexH1pCGtOySaMeta?.alias || [],
    redirect: indexH1pCGtOySaMeta?.redirect || undefined,
    component: () => import("/root/workspace/dcy-web_F0Xe/apps/admin/pages/user-management/teacher-management/index.vue").then(m => m.default || m)
  }
]