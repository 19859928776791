/*
 * @Author: 熊志伟
 * @Date: 2023-12-08 08:34:31
 * @LastEditors: 宋绍华
 * @LastEditTime: 2024-10-11 10:53:30
 * @FilePath: \dcy-web\packages\utils\constEnum.ts
 * @Description: 公用枚举
 */
// 归档状态;1-未归档，2-已归档
export enum CourseArchStatus {
  notArchive = 1, // 1-未归档
  archived = 2, // 2-已归档
}

// 课堂状态;0:未授课 1:授课中 2:授课完成
export enum CourseLessonStatus {
  unTaught, // 未授课
  teaching, // 授课中
  taught, // 授课完成
}

// 接收对象类型;1：个人;2：班级;3：项目;4：课堂讨论; 5:达成规则讨论
export enum CourseChatReceiverType {
  person = 1, // 个人
  class = 2, // 班级
  project = 3, // 项目
  classTalk = 4, // 课堂讨论
  ruleTalk = 5, // 规则讨论
}

// 习题所处阶段;1-保存  2-提交 3-通过 4-驳回 5-纠错 6-废弃
export enum PracticeState {
  save = 1, // 保存
  submit = 2, // 提交
  pass = 3, // 通过
  reject = 4, // 驳回
  correction = 5, // 纠错
  disuse = 6, // 废弃
}

export enum ClassStudentAuditStatus {
  auditing = 1, // 待审核
  passed = 2, // 已通过
  rejected = 3, // 已拒绝
}

// 习题状态
export enum ExerciseStatus {
  pending = 1, // 拆解中
  finished = 2, // 已完成
}

// 习题状态
export enum ExerciseType {
  trueorfalse = 1, // 判断题
  single = 2, // 单选题
  multiple = 3, // 多选题
  completion = 4, // 填空题
  calculation = 5, // 计算题
  shortAnswer = 6, // 简答题
  discuss = 7, // 论述题
}

// 习题状态
export enum ExerciseDifficulty {
  easy = 1, // 容易
  easier = 2, // 较易
  medium = 3, // 适中
  hard = 4, // 较难
  difficult = 5, // 困难
}

// 考核项类型
export enum ExamineProjectList {
  work = 1, // 作业
  experiment = 2, // 实验
  class = 3, // 课堂
  exam = 4, // 考试
}

// 项目归档状态
export enum ProjectArchStatusEnum {
  notArchive = 0, //  0-未归档
  archived = 1, // 1-已归档
}

// 项目负责状态
export enum ProjectIsMyselfEnum {
  noMySelf = 0, //  0-否
  isMySelf = 1, // 1-我负责的
}

// 项目状态
export enum ProjectStatusEnum {
  unStart = 0, //  未开始
  pending = 1, //  进行中
  finished = 2, //  已完成
  overdue = 3, //  已逾期
  notFinal = 4, //  未定稿
}

// 项目-任务状态
export enum ProjectTaskStatus {
  toAssign = 0, // 待指派
  toConfirm = 1, // 待确认
  doing = 2, // 进行中
  done = 3, // 完成
  toEnd = 4, // 待结束
  delay = 10, // 已延期
  end = 99, // 已结束
}
// 项目-阶段状态
export enum ProjectPhaseStatus {
  unStart = 0, // 未开始
  doing = 1, // 进行中
  done = 2, // 完成
  overdue = 3, //  逾期
}

// 项目级别
export enum ProjectTypeLevelEnum {
  first = 1, // 一级
  second = 2, // 二级
  third = 3, // 三级
}

// 总结指标
export enum SummaryMetricsEnum {
  normalGrade = 1, // 平时成绩
  defense = 2, // 总结答辩
  demonstration = 3, // 总结演示
  report = 4, // 总结报告
}

// 项目知识点、能力点类型
export enum ProjectKnowledgeTypeEnum {
  knowledgePoint = 1, // 知识点
  ablePoint = 2, // 能力点
}

// 平台
export enum Platform {
  study = 'study', // 学习端
  teaching = 'teaching', // 教学端
  admin = 'admin', // 管理平台
  resource = 'resource', // 资源端
}

// 阶段评价状态
export enum ProjectPhaseEvaluateStatus {
  unStart = 0, // 未开始
  done = 1, // 已结束
  doing = 2, // 进行中
}

// 评价规则评价方式
export enum evalMethodTypeEnum {
  teacher = 1, // 教师评价
  teacherAndStu = 2, // 教师 + 学生评价(组间互评)
}

// 项目任务动态枚举
export enum ProjectTaskDynamicStatus {
  CREATE = 1, // (1, "创建任务"),
  DESIGNATE = 2, // (2, "指派任务"),
  CREATEDESIGNATE = 3, // (3, "创建并指派任务"),
  EDIT = 4, // (4, "编辑任务"),
  EDITDESIGNATE = 5, // (5, "编辑并指派任务"),
  START = 6, // (6, "开始任务"),
  REJECT = 7, // (7, "拒绝任务"),
  RECORD = 8, // (8, "记录任务"),
  FINISH = 9, // (9, "完成任务"),
  REPULSE = 10, // (10, "打回任务"),
  PASS = 11, // (11,"通过任务"),
  REMOVE = 12, // (12,"删除任务"),
  AUTOEND = 99, // (99,"自动结束"),
}
export enum fileDirectoryTypeEnum { //  项目资料库左侧一级文件目录分类
  projectFile = '项目资料',
  groupFile = '小组资料',
}

export enum FileTypeEnum { //  项目资料库文件夹下文件类型
  directory = 1, // 文件夹
  file = 2, //  文件
}

// 预约状态
export enum BookingStatus {
  Audit = 1, // 待审核
  Success = 2, // 预约成功
  Rescinded = 3, // 已撤销
  Pause = 4, // 暂停使用
  AuditFail = 5, // 审核不通过
  Expired = 6, // 已过期
}

// 考试类型
export enum examTypeEnum {
  stage = 3, //  阶段考试
  midterm = 5, // 期中考试
  final = 6, // 期末考试
}

// 课程大纲步骤
export enum outLineStepGuideEnum {
  basicInfo = 0, //  课程基本信息
  learningOutCome = 1, // 预期学习结果
  assessmentStructure = 2, // 课程考核结构
  assessmentRules = 3, // 课程考核细则
  evaluationCriteria = 4, // 课程考核评估标准
  experimentPractice = 5, // 实验与实践
  linkStructure = 6, // 教学环节结构
  teachingPlan = 7, // 教学计划
}
