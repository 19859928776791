/*
 * @Author: 熊志伟
 * @Date: 2024-01-09 09:38:49
 * @LastEditors: 宋绍华
 * @LastEditTime: 2025-02-22 14:30:19
 * @FilePath: \dcy-web\packages\network\request.ts
 * @Description: 网络请求request
 */
import axios from 'axios'
import type { AxiosProgressEvent, AxiosRequestHeaders, Canceler } from 'axios'
import { Message } from '@arco-design/web-vue'
import { getToken } from '../utils'
import { _HOSTMAP } from '../utils/constText'

type Method = 'GET' | 'POST'

// 需要另外写错误处理样式的code码
const codeWhiteList = ['5001', '5002', '404']

const http = axios.create({
  withCredentials: true, // 跨域携带cookie
})

const CancelToken = axios.CancelToken

// 取消请求
export const cancel: {
  value?: Canceler
} = {}

http.interceptors.request.use(
  (config) => {
    const runtimeConfig: any = useRuntimeConfig()
    if (config?.data instanceof FormData) {
      config?.data?.append('appId', `${runtimeConfig?.public.appId}`)
    }
    else {
      if (config?.data?.raw) {
        // 不处理字符串
        config.data = config.data?.data
      }
      else if (config.method === 'get') {
        config.params = {
          appId: runtimeConfig?.public.appId,
          ...config.params,
        }
      }
      else {
        config.data = {
          appId: runtimeConfig?.public.appId,
          ...config?.data,
        }
      }
    }

    // header添加appid用于区分用户访问端
    config.headers.appId = runtimeConfig?.public.appId

    let token = getToken()
    const route = useRoute()
    if (route?.query?.token)
      token = route.query.token

    if (token)
      config.headers.Authorization = `Bearer ${token}`
    if (runtimeConfig?.public.appId) {
      config.headers = {
        ...config.headers,
        appId: runtimeConfig?.public.appId,
      }
    }

    const isHostReg = /^(http|https):\/\/([\w.]+\/?)\S*/

    const isHost = isHostReg.test(config.url || '')
    const configList = config.url?.split('/')
    if (config.url?.startsWith('/chat/'))
      config.url = runtimeConfig?.public?.apiIM + config?.url

    // 非域名开头和非重复dev前缀的情况下才自动加前缀
    else if (!isHost && !configList?.includes(runtimeConfig?.public?.apiBase?.slice(1)))
      config.url = runtimeConfig?.public?.apiBase + config?.url

    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)
// add response interceptors
http.interceptors.response.use(
  (response) => {
    const res = response?.data
    // 二进制文件
    if (res instanceof Blob)
      return response

    return res?.payload
  },
  ({ response }) => {
    const res = response?.data
    if ([401, 403].includes(response?.status)) {
      const runtimeConfig: any = useRuntimeConfig()
      const { platform, env } = runtimeConfig?.public
      // 管理端跳转到自己的登录页，其他跳转至官网
      const hostUrl = platform === 'operation' ? '/login' : _HOSTMAP?.[env]?.website

      // const redirect = location.pathname + location.search?.replace(/[?|&]token=.+/g, '')
      if (response?.status === 403) {
        Message.error({
          content: res.msg,
          duration: 5 * 1000,
        })
      }
      else {
        // 防止多次触发token失效message
        if (!(window as any)?.isLogouted) {
          const msg = '登录状态已过期, 请重新登录!'
          Message.error({
            content: msg,
            duration: 5 * 1000,
          })
        }
        (window as any).isLogouted = true
      }

      // 登录页token失效不进行重定向
      // if (redirect.includes('/login'))
      //   return

      const cb = () => {
        // 登录后返回之前页面
        // let loginPath = `/?redirect=${redirect}`
        // if (redirect === '/')
        //   loginPath = '/'
        // // 项目空间重定向
        // if (redirect.includes('/project/'))
        //   loginPath = '/?redirect=/project'

        // 更新页面重置window.isLogouted，延迟跳转，让用户看清提示
        // setTimeout(() => location.href = `${hostUrl}${loginPath}`, 1000)
        setTimeout(() => location.href = `${hostUrl}`, 1000)
      }
      useUserStore().logout(cb)

      return Promise.reject(new Error('token失效'))
    }
    // 文件流错误处理
    if (res instanceof Blob && res.type === 'application/json') {
      const reader = new FileReader()
      reader.readAsText(res, 'utf-8')
      reader.onload = function (e: any) {
        const data = JSON.parse(e.target.result)
        Message.error({
          content: data?.msg || '文件解析错误',
          duration: 3 * 1000,
        })
      }
      return Promise.reject(response)
    }
    if (!codeWhiteList.includes(res?.code)) {
      if (!res?.code)
        return
      Message.error({
        content: res?.msg || '服务器错误，请联系管理员',
        duration: 3 * 1000,
      })
    }
    return Promise.reject(response)
  },
)

/**
 *
 * @param url 请求地址
 * @param data 请求参数
 * @param method 请求方式
 * @param transformBlob 是否是文件流导入
 * @param headers 请求头
 */
export function request<T>(url: string, data?: any, method: Method = 'POST', transformBlob = false, headers?: AxiosRequestHeaders, onProgress?: (progressEvent: AxiosProgressEvent) => void): Promise<T> {
  return http({
    url,
    cancelToken: new CancelToken((c) => {
      cancel.value = c
    }),
    method,
    data: method === 'POST' ? data : undefined,
    params: method === 'GET' ? data : undefined,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    responseType: transformBlob ? 'blob' : 'json',
    onUploadProgress: onProgress, // 添加上传进度回调
    transformResponse: [function (data: any) {
      try {
        // 处理二进制文件
        if (data instanceof Blob)
          return data
        return JSON.parse(data)
      }
      catch (err) {
        return JSON.parse(data)
      }
    }],
  })
}
