/*
 * @Author: 熊志伟
 * @Date: 2023-12-13 16:17:36
 * @LastEditors: 熊志伟 xiongzhiwei
 * @LastEditTime: 2025-03-25 09:31:43
 * @FilePath: \dcy-web\packages\utils\flat.ts
 * @Description: 拍平数组
 */

/**
 * 拍平数组
 * @param data 需要拍平的树形
 * @param type 需要筛选的字段名
 * @param code 需要筛选的字段名对应的值, 1为路由 2为按钮
 * @returns 筛选并拍平后的一维数组
 */
export function flatData(data: any[], type = 'type', code = 1) {
  if (!data?.length)
    return []
  return data.reduce((prev, curr) => {
    if (type && code) {
      if (curr[type] === code)
        prev.push(curr)
    }
    else { prev.push(curr) }

    if (curr.children?.length > 0)
      prev.push(...flatData(curr.children, type, code))

    return prev
  }, [])
}

/**
 * 通过type筛选树形data
 * @param data 需要拍平的树形
 * @param type 需要筛选的字段名
 * @param code 需要筛选的字段名对应的值 1为路由 2为按钮
 * @returns
 */
export function filterData(data: MenuDTO[], type = 'type', code = 1) {
  if (!data?.length)
    return []
  const newData = data?.filter(x => x[type] === code)
  newData.forEach(x => x.children && (x.children = filterData(x.children, type, code)))
  return newData
}

// 拍平tree
// 定义一个将树形结构转换为一维数组的函数
export function treeToArray(treeData, childNode = 'children') {
  const result = []

  function traverseNode(node) {
    // 将当前节点添加到结果数组中
    result.push(node)

    if (node[childNode]) {
      // 递归遍历子节点
      for (const child of node[childNode])
        traverseNode(child)
    }
  }

  traverseNode(treeData)

  return result
}

// 寻找对象数组中符合条件的每一项
export function findAllItemsInTree(tree, condition: Function) {
  if (!tree?.length)
    return []
  let result = []
  for (const node of tree) {
    if (condition(node))
      result.push(node)

    const childrenResult = findAllItemsInTree(node.children || [], condition)
    result = result.concat(childrenResult)
  }
  return result
}
