/*
 * @Author: 熊志伟
 * @Date: 2023-12-11 09:13:07
 * @LastEditors: 娄松
 * @LastEditTime: 2024-08-05 13:40:40
 * @FilePath: \dcy-web\apps\teaching-space\stores\modules\user\account.ts
 * @Description: user modules
 */
import { acceptHMRUpdate, defineStore } from 'pinia'
import to from 'await-to-js'
import type { AccountDetailResponse } from '@/services/types/user'
import { useUserStore } from '@/stores/index'

import {
  getUserDetail,
} from '@/services/user'

const useUserAccountStore = defineStore('account', {
  state: (): AccountDetailResponse => ({
    accountStudent: undefined,
    accountTeach: undefined,
    /**
     * 应用ID
     */
    appId: 0,
    /**
     * 编号
     */
    code: '',
    /**
     * 创建人id
     */
    createdBy: 0,
    /**
     * 创建人名称
     */
    createdByName: '',
    /**
     * 账号头像OSS文件id
     */
    iconId: 0,
    /**
     * 账号头像OSS文件
     */
    iconUrl: '',
    /**
     * 主键id
     */
    id: 0,
    /**
     * 身份证
     */
    idCard: '',
    /**
     * 名称
     */
    name: '',
    /**
     * 手机号
     */
    phone: '',
    /**
     * 备注
     */
    remark: '',
    /**
     * 启用状态
     */
    status: false,
    /**
     * 更新人id
     */
    updatedBy: 0,
    /**
     * 更新人名称
     */
    updatedByName: '',
    /**
     * 人脸照片
     */
    faceUrl: '',
    // 身份类型：1：教职工，2：学生，3：散客
    accountType: 3,
    // 组织名称
    organizationName: '',
    organizationId: '', // 组织id
    accountRoleList: [], // 角色列表
  }),

  persist: {
    storage: persistedState.localStorage,
    paths: [],
  },

  getters: {
    userAccountInfo(state: AccountDetailResponse) {
      return state.userAccountInfo
    },
  },

  actions: {
    resetInfo() {
      this.$reset()
    },
    // Set user's information
    setInfo(partial: AccountDetailResponse) {
      this.$patch(partial)
    },
    // 获取用户身份信息
    async userDetail() {
      const user = useUserStore()
      if (!user.$state.accountId)
        await user.info()

      const [_, data] = await to<AccountDetailResponse>(getUserDetail(user.$state.accountId!))
      if (data)
        this.setInfo(data)
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useUserAccountStore as any, import.meta.hot))

export default useUserAccountStore
