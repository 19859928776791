/*
 * @Author: 宋绍华
 * @Date: 2024-01-15 09:49:40
 * @LastEditors: huang liang miao 1127255342@qq.com
 * @LastEditTime: 2025-03-14 14:41:13
 * @FilePath: \dcy-web\packages\materials\services\common.ts
 * @Description: 通用接口
 */
import type { RequestOption } from '@arco-design/web-vue'
import { request } from '../../network'
import { getToken } from '../../utils'
import type { MessageNoticeUnReadNumDTO, RespBasePageInter } from '~/types/base'
import type { BaseSuccessResp, CourseListQuery, CourseListResponse, ExercisesLatexToSvgRespOpenDTO, LatexBody, MajorListQuery, MajorListResponse, MessageNoticePageQuery, OssUploadResp, PageBeanMessageNoticePageVO, ExercisesLibraryPageQueryReq, ExercisesLibraryPageResp, ChatClassAndPrivateListResp } from '~/types/base'
import type { AccountBatchCreateOpenReqDTO, AccountPageVO, ClassMemberImportReqOpenDTO, KnowledgePointBatchCreateOpenReqDTO, KnowledgePointTreeVO, OutlineImportOpenReqDTO } from '~/types/course'
import type { AxiosProgressEvent } from 'axios'
import type { OutlineExperimentListResponse } from '~/types/syllabus'

const baseUrl = ''
const PROGRESS_ADJUSTMENT = 0.3
const token = getToken()

// 文件类型;1：视频；2：图片；3：文本；4：音频；5：办公文件；0：其他
// OSS的文件类型枚举
export function getOssUploadReq(formData: FormData, option?: RequestOption): Promise<OssUploadResp> {
  let progressCb = (_progressEvent: AxiosProgressEvent) => {}
  if(option) {
    progressCb =  (progressEvent: AxiosProgressEvent) => {
      let progress = Math.ceil(progressEvent.event.loaded / progressEvent.event.total)
      // 修正进度值，同时保证不低于0
      progress = Math.max(0, progress - PROGRESS_ADJUSTMENT)
     // 修复服务端时延，进度条提前到达 100%
     option.onProgress(progress, progressEvent.event)
   }
  }
  return request<OssUploadResp>(`/common/oss/upload`, formData, 'POST', false, { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` }, progressCb)
}

export function getLatex2SvgUrl(params: LatexBody): Promise<ExercisesLatexToSvgRespOpenDTO> {
  return request<OssUploadResp>(`/exercises/exercisesReach/latexToSvg`, { ...params })
}

// 实验/实践列表
export function getExperimentOrPraticeListData(data: { outlineId: number, type: number }) {
  return request<OutlineExperimentListResponse>('/project/info/experimentList', data)
}

// 大纲导入
export function getImportTemplateReq(data?: OutlineImportOpenReqDTO) {
  return request(`/course/platform/outline/import`, data, 'POST', true)
}
// 知识点导入
export function getImportTemplateKnowledgepointReq(data?: KnowledgePointBatchCreateOpenReqDTO) {
  return request(`/basic/knowledgepoint/batchCreate`, data, 'POST', true)
}
// 管理端教师、学生、运营导入
export function getImportTemplateaCcountReq(data?: AccountBatchCreateOpenReqDTO) {
  return request(`/resource/account/batchCreate`, data, 'POST', true)
}
// 运营端教师、学生、运营导入
export function getImportBatchCreateReq(data?: AccountBatchCreateOpenReqDTO) {
  return request(`/operation/resource/account/batchCreate`, data, 'POST', true)
}
// 班级学生导入
export function getImportTemplateaClassStudentReq(data?: ClassMemberImportReqOpenDTO) {
  return request(`/course/instance/class/importClassMember`, data, 'POST', true)
}

// 行政班学生导入
export function getImportMemberByAdmReq(data?: ClassMemberImportReqOpenDTO) {
  return request(`/course/instance/class/importMemberByAdm`, data, 'POST', true)
}

// 导入学生成绩
export function getImportStudentScore(data?: ClassMemberImportReqOpenDTO) {
  return request(`/course/task/exercises/importExamineScore`, data, 'POST', true)
}

// 年级班级导入
export function getImportTemplateaBatchCreateReq(data?: ClassMemberImportReqOpenDTO) {
  return request(`/resource/manager/class/batchCreate`, data, 'POST', true)
}
// 院系导入
export function getImportTemplateaMajorBatchCreateReq(data?: ClassMemberImportReqOpenDTO) {
  return request(`/resource/department/batchCreate`, data, 'POST', true)
}
/**
 * 知识点树状
 * @param {string} courseId
 * @param {number} type 1.知识点2.能力点
 * returns {classListResp[]}
 */
export function getKnowledgepointListReq(data: { courseId?: string, type: number }) {
  return request<KnowledgePointTreeVO[]>(`/basic/knowledgepoint/tree`, data)
}

// 基础数据-课程列表
export function getCourseListReq(data?: CourseListQuery) {
  return request<CourseListResponse[]>(`${baseUrl}/basic/course/list`, data)
}

// 基础数据-专业列表
export function getMajorListReq(data?: MajorListQuery) {
  return request<MajorListResponse[]>(`${baseUrl}/basic/major/list`, data)
}

/**
 * 获取习题库列表
 * @param {object} getExercisesLibraryPagesReq
 * @returns {object} ExercisesLibraryPageResp
 */
export function getExercisesLibraryPagesReq(params?: ExercisesLibraryPageQueryReq) {
  return request<RespBasePageInter<ExercisesLibraryPageResp>>(`${baseUrl}/exercises/exercisesLibrary/page`, params)
}

/**
 * 获取消息分页
 * param
 */
export function getUnreadMessage(data: MessageNoticePageQuery) {
  return request<PageBeanMessageNoticePageVO>(`/common/message/page`, data)
}

/**
 * 获取消息分页
 * param
 */
export function getMessagePage(data: MessageNoticePageQuery) {
  return request<PageBeanMessageNoticePageVO>(`/common/message/page`, data)
}

/**
 * 获取未读消息详细数量
 * param
 */
export function getMessageUnReadNum() {
  return request<MessageNoticeUnReadNumDTO>(`/common/message/unReadNum`, {}, 'GET')
}

/**
 * 设置消息为已读
 * param
 */
export function setMessageRead(data: MessageNoticePageQuery) {
  return request<BaseSuccessResp>(`/common/message/read`, data)
}

/**
 * 设置所有消息为已读
 * param
 */
export function setAllMessageRead(data: { type: string }) {
  return request<BaseSuccessResp>(`/common/message/allRead`, data)
}


/**
 * 校验消息主营业务是否正常
 * param
 */
export function validateMessage(data: any) {
  return request<BaseSuccessResp>(`/common/message/check/business/${data.id}`)
}

export function getUserDetail(id: number) {
  return request<AccountPageVO>(`/resource/account/detail/${id}`, {}, 'GET')
}

/**
 * 发送验证包验证已登录
 * param
 */
export function validateIsLogin(data?: { type: string }) {
  return request<BaseSuccessResp>(`/resource/detail`, {
    isHeartBeat: true,
    ...data
  }, 'GET')
}

/**
 * 获取未读消息列表（默认3条，用于聊天下拉图标显示） -- 课程班级群聊私聊
 * @param {}
 * @returns {getUnreadChatListReq[]}
 */
export function getUnreadChatListReq(): Promise<ChatClassAndPrivateListResp[]> {
  return request<ChatClassAndPrivateListResp[]>(`${baseUrl}/course/chat/icon`)
}

